import {
  BFF_STATUS,
  PAGE_CONSENT_MANAGEMENT,
  PARAM_THEME_ID,
  PARAM_FIRSTNAME,
  PARAM_LASTNAME,
  PAGE_CODE_SEND,
  PARAM_CSRF_TOKEN,
  PAGE_AUTH,
  PAGE_ADD_EMAIL,
  IDENTITY_PROVIDER,
  PAGE_REDIRECT,
  PAGE_MOBILE_CODE_SEND,
  PAGE_COMPLETE_ACCOUNT,
  PAGE_TNC_MANAGEMENT,
  OTP_BUTTON_LABEL
} from '../constants'
import { handleRegion } from '../services/axiosConfig'
import config from '../config'
import { parseTNC, setCSRFToken, setLocale } from '../util'
import bff from '../services'
import { history } from '../store'

const session = {
  state: {
    client: {
      brandingImages: [],
      displayName: '',
      clientId: ''
    },
    sessionId: '',
    supportedIDP: [],
    supportedOneHP: false,
    captchaEngines: [],
    tncs: [],
    isTNCLoaded: false,
    scopes: [],
    sessionIdentityResource: {},
    approved: false,
    [PARAM_THEME_ID]: '',
    startTime: '',
    warned: false,
    smsSupportedCountryNumbers: [],
    [PARAM_CSRF_TOKEN]: '',
    regionEndpointUrl: config.backendUrl,
    retryDelay: false,
    timestampDelay: 0,
    displayUpdateMessage: true,
    phoneVerificationSendCode: '',
    showPhoneField: false,
    signUpEventSent: false,
    arkoseDataExchange: false,
    otpSignInEmail: false,
    otpSignInSMS: false,
    arkoseTooManyRequests: false,
    otpLoginButton: OTP_BUTTON_LABEL.USE_ONE_TIME_PASSCODE,
    enableDynamicaYield: false
  },
  reducers: {
    update: (state, data) => {
      return { ...state, ...data }
    }
  },
  effects: (dispatch) => ({
    updateSession(data, rootState) {
      const {
        client,
        status,
        sessionIdentityResource,
        captchaEngines,
        regionEndpointUrl,
        csrfToken,
        idp,
        sessionId,
        features
      } = data

      if (client.displayName !== rootState?.udl.clientID) dispatch.udl.update({ clientID: client.displayName })

      if (csrfToken) {
        dispatch.session.update({ [PARAM_CSRF_TOKEN]: csrfToken })
        setCSRFToken(csrfToken)
      }

      dispatch.session.update({ client, captchaEngines })
      if (sessionId) dispatch.session.update({ sessionId })
      if (regionEndpointUrl) dispatch.session.update({ regionEndpointUrl })
      if (sessionIdentityResource) dispatch.session.updateUserSession(sessionIdentityResource)

      if (features) {
        dispatch.session.update({
          showPhoneField: features['sign-up-show-phone-field'],
          arkoseDataExchange: features.dataExchange,
          otpSignInEmail: features['otp-login-email'],
          otpSignInSMS: features['otp-login-sms'],
          otpLoginButton: features['otp-login-button'],
          enableDynamicaYield: features['dynamic-yield']
        })
      }

      // validate if OneHP provider is available in the client
      if (!!idp && idp.length > 0) {
        const supportedOneHP = idp.some((i) => i.name.toLowerCase() === IDENTITY_PROVIDER.ONE_HP.toLowerCase())
        dispatch.session.update({ supportedOneHP })
      }

      switch (status) {
        case BFF_STATUS.TNC_VALIDATION_REQUIRED:
          return history.replace(PAGE_TNC_MANAGEMENT)
        case BFF_STATUS.PHONE_VERIFICATION:
          dispatch.user.update({ phoneNumber: data.phoneNumber })
          return history.replace(PAGE_MOBILE_CODE_SEND)
        case BFF_STATUS.EMAIL_VERIFICATION:
          dispatch.user.update({ email: data.email })
          return history.replace(PAGE_CODE_SEND)
        case BFF_STATUS.EMAIL_OR_PHONE_VERIFICATION:
          dispatch.user.update({
            phoneNumber: data.phoneNumber,
            email: data.email
          })
          return history.replace(PAGE_MOBILE_CODE_SEND)
        case BFF_STATUS.EMAIL_REQUIRED:
          return history.replace(PAGE_ADD_EMAIL)
        case BFF_STATUS.CONSENT_MANAGEMENT:
          return history.replace(PAGE_CONSENT_MANAGEMENT)
        case BFF_STATUS.MFA_REQUIRED:
          dispatch.user.update({ availableMfaMethods: data.availableMfaMethods })
          return history.replace(PAGE_AUTH)
        default:
          return dispatch.session.handleSupportedIDPs(data)
      }
    },

    async handleSupportedIDPs(data) {
      const { idp = [] } = data
      const supportedIDP = config.identityProviders.filter((item) => {
        return idp.find((i) => i?.name?.toLowerCase() === item?.name?.toLowerCase())
      })
      dispatch.session.update({ supportedIDP })
    },

    updateUserSession(sessionResource) {
      dispatch.user.update({
        [PARAM_LASTNAME]: sessionResource.name && sessionResource.name.familyName,
        [PARAM_FIRSTNAME]: sessionResource.name && sessionResource.name.givenName,
        locale: sessionResource.locale,
        country: sessionResource.countryResidence,
        username: sessionResource.userName,
        identityProvider: sessionResource.identityProvider
      })
      setLocale(sessionResource.locale)
      dispatch.user.changeLanguage()
    },

    async initSessionWithFlow(initSessionParams) {
      initSessionParams['split-sign-up'] = true
      const { data } = await bff.postAuthSession(initSessionParams)
      dispatch.session.updateSession(data)
      await handleRegion(data.regionEndpointUrl)
      return data
    },

    async acceptOrDeclineTermsAndConditions(data) {
      const {
        options: { setLoading },
        termsAndConditions
      } = data
      try {
        const { data } = await bff.postTermsAndConditions({ termsAndConditions })
        setLoading({ accept: false, decline: false })
        if (data.nextUrl) {
          history.push(PAGE_REDIRECT, { nextUrl: data.nextUrl })
        } else {
          await dispatch.user.userCheckFlowURI(data)
        }
      } catch (error) {
        setLoading({ accept: false, decline: false })
        dispatch.error.goError(error)
      }
    },

    async approveConsentFlow({ options: { setLoading }, approved, optionalScopes }) {
      try {
        const result = await dispatch.session.approveConsent({ approved, optionalScopes })
        setLoading(false)
        if (result.status === BFF_STATUS.COMPLETION_REQUIRED) {
          history.push(PAGE_COMPLETE_ACCOUNT)
        } else if (result.nextUrl) {
          history.push(PAGE_REDIRECT, { nextUrl: result.nextUrl })
        } else {
          dispatch.error.goError()
        }
      } catch (errorData) {
        setLoading(false)
        dispatch.error.goError(errorData)
      }
    },

    async approveConsent({ approved, optionalScopes }) {
      const { data } = await bff.postScopeConsents({
        approved: approved,
        optionalScopes: optionalScopes
      })
      return data
    },

    async fetchCaptchaEngines() {
      try {
        const { data } = await bff.getCaptchaEngines()
        const {
          features = {
            showPhoneField: false,
            dataExchange: false
          }
        } = data
        dispatch.session.update({
          captchaEngines: data?.captchaEngines,
          arkoseDataExchange: features.dataExchange
        })
      } catch (errorData) {
        dispatch.error.goError(errorData)
      }
    },

    async fetchSessionInfo(_, rootState) {
      await handleRegion(rootState.session.regionEndpointUrl)
      const { data } = await bff.getSessionInfo()
      dispatch.session.updateSession(data)
      return data
    },

    // this is a temporary fucntion created in order to provide
    // information to debug easter egg page
    async fetchSessionCustomInfo(_) {
      return bff.getSessionInfo()
    },

    async fetchTermsAndConditions({ locale, country }) {
      const params = { locale, country }
      const { data } = await bff.getTermsAndConditions(params)
      const tncs = parseTNC(data.tncs)
      dispatch.session.update({ tncs })
    },

    async fetchSingUPTermsAndConditions({ locale, country }) {
      try {
        const params = { locale, country }
        const { data } = await bff.getSignUpTncs(params)
        const tncs = parseTNC(data.tncs)
        dispatch.session.update({ tncs, isTNCLoaded: true })
      } catch (errorData) {
        dispatch.error.goError(errorData)
      }
    },

    async fetchSmsSupportedCountryNumbers() {
      try {
        const { data } = await bff.getSmsSupportedCountryNumbers()
        dispatch.session.update({ smsSupportedCountryNumbers: data })
      } catch (errorData) {
        dispatch.error.goError(errorData)
      }
    },

    async getConsent() {
      try {
        const res = await bff.getScopeConsents()
        const { sessionIdentityResource, scopes, approved } = res.data
        this.updateUserSession(sessionIdentityResource)
        this.update({
          scopes: scopes,
          sessionIdentityResource: sessionIdentityResource,
          approved: approved
        })
        return res.data
      } catch (errorData) {
        dispatch.error.goError(errorData)
      }
    },

    async sendUIEvents(eventName) {
      try {
        await bff.postUIEvents({ eventName })
      } catch (error) {
        console.error('Error while sending UI event', error)
      }
    }
  })
}

export default session
